<template>
  <b-container class="mb-3">
    <p class="text-center my-3 px-3 p-sm-0 py-0 py-md-3 w-100">{{ $t('login_electroneum_full') }}</p>
    <b-spinner large label="Large Spinner"></b-spinner>
  </b-container>
</template>

<script>
import ApiService from '../../apiService.js'
import { mapActions } from 'vuex'
import config from '../../config'

export default {
  name: 'Sso',
  data () {
    return {
      resp: []
    }
  },
  mounted () {
    this.loginCheckSso()
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    loginCheckSso () {
      const sso = this.$route.query.sso
      const sig = this.$route.query.sig
      if (!sso || !sig) {
        this.$router.push({ name: 'home' })
        return
      }

      ApiService.loginCheckSso(sso, sig).then(async response => {
        if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            'event': 'login'
          })
        }

        localStorage.setItem('accessToken', response.data.data.token)
        localStorage.setItem('refreshToken', response.data.data.refresh_token)
        this.processJwt().then(() => {
          if (localStorage.getItem('role_seller')) {
            localStorage.setItem('signUpProcess', 'success')
          }
        })

        if (localStorage.getItem('utm')) {
          await ApiService.patchUser(JSON.parse(localStorage.getItem('utm')), true).then(() => {
            localStorage.removeItem('utm')
          }).catch()
        }

        let returnPath = localStorage.getItem('ssoReturnPath')
        if (returnPath) {
          localStorage.removeItem('ssoReturnPath')
        } else {
          returnPath = '/'
        }

        switch (this.$route.query.push_redirect) {
          case 'anytask_account':
            returnPath = '/my-account/'
            break
          case 'anytask_orders_sold':
            returnPath = '/my-account/sales'
            break
          case 'anytask_inbox':
            returnPath = '/my-account/inbox'
            break
          case 'anytask_dispute':
            returnPath = '/my-account/sales/#disputed'
            break
        }

        if (response.data.data.linked === true) {
          this.$swal({
            title: this.$t('sso_linked_account.title'),
            html: this.$t('sso_linked_account.content'),
            type: 'info',
            confirmButtonText: this.$t('sso_linked_account.button')
          }).then(() => {
            window.location.assign(returnPath)
          })
        } else {
          window.location.assign(returnPath)
        }
      }).catch(error => {
        this.$store.state.loginError = error.response.data.error_message
        this.$store.state.loginStatus = error.response.status
        this.$router.push('/#login_sso')
      })
    }
  }
}
</script>
